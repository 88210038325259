import React from 'react';
import { useTranslation } from 'react-i18next';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import BankIdButton from '../../component/BankIdButton/BankIdButton';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../route';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const chooseBankId = () => {
    navigate(APP_ROUTES.BANK_ID);
  };

  const chooseLoginCode = () => {
    navigate(APP_ROUTES.LOGIN_CODE);
  };

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText title={t('loginForm.heading.heading')} description={t('loginForm.heading.subHeading')}/>

        <div className="pb-20 choose-auth-btn">
          <BankIdButton
            onClick={chooseBankId}
            classes={'g_btn-full-width'}
            label={t('loginForm.button.bankId')}
          />
        </div>
        <div className="choose-auth-btn">
          <button className="login-btn login-btn__login-code g_btn-full-width" onClick={chooseLoginCode}>
            {t('loginForm.button.loginCode')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default Login;
