import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, } from 'react-router-dom'
import LogoWithText from '../../component/LogoWithText/LogoWithText'
import { APP_ROUTES } from '../../route'
import 'react-international-phone/style.css'
import dayjs from 'dayjs'
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from '../../common/local-storage'
import { AppointmentDto } from '../../sdk'

function BookingConfirmation() {
  const { t } = useTranslation()
  const appointment = getLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment)
  const meetingDate = appointment?.date ? dayjs(new Date(appointment.date)).format('dddd, D MMM - HH:mm') : '-'
  const navigate = useNavigate()

  const handleSubmit = () => {
    navigate(APP_ROUTES.DASHBOARD)
  }
  const Description = () =>
   <p className="description c_grey h4">{t('bookingPage.successStep.description')}: <span className="bold">{meetingDate}</span><br/><br/>{t('bookingConfirmationPage.description')}</p>


  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">

        <LogoWithText
          title={t('bookingConfirmationPage.title')}
          descriptionComponent={<Description />}
        />

        <div className="pt-20">
          <button
            className="g_btn g_btn--primary g_btn-full-width"
            onClick={handleSubmit}>
            {t('bookingConfirmationPage.button')}
          </button>
        </div>

      </div>
    </main>
  )
}

export default BookingConfirmation
