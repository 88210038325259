import { api } from './api';
import { AppointmentDto, PaymentIntentDto } from '../sdk';

export const PaymentRepository = {
  async createPaymentIntent(): Promise<PaymentIntentDto> {
    return api.payment.createPaymentIntent();
  },

  async processFreeCard(appointmentId: string): Promise<AppointmentDto> {
    return api.payment.useFreeCard({ appointmentId });
  },
};