import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '../../component/Layout/Layout'
import ButtonBox from '../../component/ButtonBox/ButtonBox'
import DoctorReport from '../../asset/img/icon/doctorReport.svg'
import Mobile from '../../asset/img/icon/mobile.svg'
import { useAppState } from '../../store/app-state'
import { useDownloadFile, useGetFiles } from '../../query/use-user'
import './Dashboard.scss'

function Dashboard() {
  const { t } = useTranslation()
  const [showInfo, setShowInfo] = useState(false)
  const user = useAppState((state) => state.user)
  const { data: reports } = useGetFiles()
  const { mutate: downloadFile } = useDownloadFile()

  const handleClick = () => {
    setShowInfo(!showInfo)
  }

  const savePdfToPc = (blob: Blob, filename: string) => {
    const myURL = window['URL'] || window['webkitURL']
    const url = myURL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    // the filename you want
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    myURL.revokeObjectURL(url)
  }

  const handleDownloadReport = async () => {
    if (reports && reports.length > 0) {
      const id = reports[reports.length - 1]

      downloadFile(id, {
        onSuccess: (blob) => {
          const filename = `${user?.firstName}_${user?.lastName}.pdf`
          savePdfToPc(blob, filename)
        }
      })

    }
  }

  return (
    <div className="App">
      <Layout>
        <div className="dashboard__holder p-20 mh-auto">
          {user && <h1
              className="h2 pt-20 textAlign-center m-20">{t('dashboardPage.header')} {`${user.firstName}`}!</h1>}
          <h1 className="h4 mb-40 mr-20 ml-20 textAlign-center">{t('dashboardPage.subHeader')}</h1>

          <ButtonBox
            link="https://minnesmottagningen.se/app"
            buttonText={t('dashboardPage.box2.btn')}
            picture={Mobile}
            additionalText={t('dashboardPage.box2.text')}
          ></ButtonBox>
          {!!(reports && reports.length) &&
              <div className={`component-container ${showInfo ? 'expanded' : ''}`} onClick={handleClick}>
                  <div className="row-container p-10">
                      <a className="dashboard-heading-link" onClick={handleDownloadReport}>
                          <button
                              className="g_btn g_btn--primary g_btn-full-width">{t('dashboardPage.download-file.button')}</button>
                      </a>
                      <img src={DoctorReport} alt="Picture" className="picture" />
                  </div>
                  <p>{t('dashboardPage.download-file.text')}</p>
              </div>
          }
        </div>
      </Layout>
    </div>
  )
}

export default Dashboard
