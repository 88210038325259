import React from 'react';
import { ModalTypes, useModalState } from '../../../store/modal-state';
import Spinner from '../Spinner/Spinner';
import './ModalOverlay.scss';
import FreeCard from '../FreeCard/FreeCard';

const ModalOverlay: React.FC = () => {
  const { opened, type, modalProps, closeModal } = useModalState();

  const { dismissible , onSuccess } = modalProps;

  if (!opened) return null;

  let content;
  switch (type) {
    case ModalTypes.Spinner:
      content = <Spinner />;
      break;
    case ModalTypes.FreeCard:
      content = <FreeCard dismissible={dismissible} onSuccess={onSuccess} />;
      break;
    default:
      content = null;
  }

  return (
    <div className="modal-overlay" onClick={dismissible ? closeModal : () => null}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {content}
      </div>
    </div>
  );
};

export default ModalOverlay;
