import React from 'react'
import { useWindowDimensions } from '../../hook/windowDimensionHandler'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import { ReactComponent as Logo } from '../../asset/img/logo/minnesmottagningen.svg'

import './Header.scss'
import Logout from '../Logout/Logout'

const Header: React.FC = () => {
  const { width } = useWindowDimensions()
  const isDesktop = width > 768
  return (
    <header className="ph-20 pv-20 header h4">
      <div className="flex justify-between align-center">
        <div className="logo-wrapper-dashboard">
          <div className="logo-wrapper">
            <Logo />
          </div>
        </div>
        {isDesktop && <Logout />}
        {!isDesktop && <BurgerMenu />}
      </div>
    </header>
  )
}

export default Header
