import React from 'react'
import { NavLink } from 'react-router-dom'
import { NavigationLink } from '../../constant/compliance-links'
import { useTranslation } from 'react-i18next'

type IProps = {
  menuItem: NavigationLink,
  classNames?: string
}

const SimpleNavItem = (props: IProps) => {
  const { menuItem, classNames = '' } = props
  const { i18n, t } = useTranslation()
  const currentLanguage = i18n.language

  if (menuItem.isExternal) {
    const link = menuItem.externalLinks ? menuItem.externalLinks[currentLanguage] : ''
    return (
      <li className={`${classNames} nav-item`}>
        <a href={link} target="_blank" rel="noreferrer">{t(menuItem.title)}</a>
      </li>
    )
  }

  return (<li className={`${classNames} nav-item`}>
    <NavLink to={menuItem.internalLink}>{t(menuItem.title)}</NavLink>
  </li>)
}

export default SimpleNavItem
