import React from 'react'
import Navbar from '../NavBar/NavBar'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

// import AcivityToLogoutWatcher from '../ActivityToLogoutWatcher'

interface IProps {
  children?: React.ReactNode;
}

const Layout = (props: IProps) => {
  return (
    <div className="wrapper flex flex-column">
      {/*<AcivityToLogoutWatcher />*/}
      <Header />
      <Navbar />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
