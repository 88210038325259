import React from 'react';
import './FreeCard.scss';
import { useTranslation } from 'react-i18next';
import { closeModal, ModalProps } from '../../../store/modal-state';
import FreeCardImg from '../../../asset/img/freecard.svg';

const FreeCard = (props: ModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="free-card">
      <div className="flex justify-center pb-20">
        <img src={FreeCardImg} style={{ width: '50%', marginLeft: '8px' }} alt="" />
      </div>
      <div className="flex-column align-center">
        <h1 className="h2 t_center mb-20">{t('bookingPage.paymentStep.freeCardConfirm')}</h1>
        <h1 className="h5 textAlign-center mb-20 pl-20 pr-20">
          {t('bookingPage.paymentStep.freeCardConfirmHint')}
        </h1>
        <button onClick={props.onSuccess} className="g_btn g_btn--primary g_btn-full-width mb-10">
          {t('bookingPage.paymentStep.freeCardConfirmBtn')}
        </button>
        <button
          className="g_btn g_btn--outlined g_btn-full-width mt-10"
          onClick={() => closeModal()}
        >
          {t('bookingPage.paymentStep.freeCardCancelBtn')}
        </button>
      </div>
    </div>
  );
};

export default FreeCard;
