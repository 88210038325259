import { NavigationLink } from './compliance-links'

export const footerLinks: Array<NavigationLink> = [
  {
    title: 'footer.link.termsOfUse',
    internalLink: '',
    externalLinks: {
      en: 'https://en.minnesmottagningen.se/anvandarvillkor',
      sv: 'https://www.minnesmottagningen.se/anvandarvillkor'
    },
    isExternal: true
  },
  {
    title: 'footer.link.privacyPolicy',
    internalLink: '',
    externalLinks: {
      en: 'https://en.minnesmottagningen.se/integritetspolicy',
      sv: 'https://www.minnesmottagningen.se/integritetspolicy'
    },
    isExternal: true
  }
]

export const footerContacts = {
  companyName: 'Geras Solutions AB',
  street: 'Norrtullsgatan 6',
  city: '113 29 Stockholm, Sverige',
  index: '+46 (0)10-750 07 26'
}

