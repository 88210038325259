import { api } from './api';
import { AppointmentDto, AvailableDatesDto, AvailableTimesDto } from '../sdk';

export const AppointmentRepository  = {
  async getAppointmentById(id: string): Promise<AppointmentDto> {
    return api.appointment.getAppointment(id)
  },
  async getAvailableDates(month: string, timezone: string): Promise<AvailableDatesDto[]> {
    return api.appointment.getAvailableDates(month, timezone)
  },
  async getAvailableTimes(date: string, timezone: string): Promise<AvailableTimesDto[]> {
    return api.appointment.getAvailableTimes(date, timezone)
  },
  async createAppointment(datetime: string) {
    return api.appointment.createAppointment({ datetime });
  },
  async cancelAppointmentById(id: string): Promise<boolean> {
    return api.appointment.cancelReservation(id)
  },
  async handlePaidAppointment(paymentIntentId: string): Promise<AppointmentDto> {
    return api.appointment.handlePaidAppointment(paymentIntentId)
  },
}