import { NavigationLink } from './compliance-links'

export interface HeaderLink extends NavigationLink {
  subLinks: Array<NavigationLink>
}

export const headerLinks: Array<HeaderLink> = [
  {
    title: 'navItem.myAssessmentProcess',
    internalLink: '/dashboard',
    externalLinks: null,
    isExternal: false,
    subLinks: []
  },
  {
    title: 'navItem.aboutTheBrain.main',
    internalLink: '',
    externalLinks: null,
    isExternal: true,
    subLinks: [
      {
        title: 'navItem.aboutTheBrain.aboutTheBrain',
        internalLink: '',
        externalLinks: {
          en: 'https://en.minnesmottagningen.se/hall-hjarnan-frisk/om-hjarnan',
          sv: 'https://www.minnesmottagningen.se/hall-hjarnan-frisk/om-hjarnan'
        },
        isExternal: true
      },
      {
        title: 'navItem.aboutTheBrain.whatIsDementia',
        internalLink: '',
        externalLinks: {
          en: 'https://en.minnesmottagningen.se/sjukdomar/vad-ar-demenssjukdom',
          sv: 'https://www.minnesmottagningen.se/sjukdomar/vad-ar-demenssjukdom'
        },
        isExternal: true
      },
      {
        title: 'navItem.aboutTheBrain.howCommonIsDementia',
        internalLink: '',
        externalLinks: {
          en: 'https://en.minnesmottagningen.se/sjukdomar/hur-vanligt-ar-det-med-demenssjukdom',
          sv: 'https://www.minnesmottagningen.se/sjukdomar/hur-vanligt-ar-det-med-demenssjukdom'
        },
        isExternal: true
      },
      {
        title: 'navItem.aboutTheBrain.riskFactorsForDementia',
        internalLink: '',
        externalLinks: {
          en: 'https://en.minnesmottagningen.se/hall-hjarnan-frisk/riskfaktorer-for-demenssjukdom',
          sv: 'https://www.minnesmottagningen.se/hall-hjarnan-frisk/riskfaktorer-for-demenssjukdom'
        },
        isExternal: true
      },
    ]
  },
  {
    title: 'navItem.keepBrainHealthy',
    internalLink: '',
    externalLinks: {
      en: 'https://en.minnesmottagningen.se/hallhjarnanfrisk',
      sv: 'https://www.minnesmottagningen.se/hallhjarnanfrisk'
    },
    isExternal: true,
    subLinks: []
  },
  {
    title: 'navItem.aboutApp',
    // hardcoding because of compile issue
    internalLink: '/about-app',
    externalLinks: null,
    isExternal: false,
    subLinks: []
  },
]
