import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../asset/img/logo/minnesmottagningen.svg';

import './LogoWithText.scss'

interface IProps {
  title?: string;
  description?: string;
  subTitle?: string;
  withLanguageSelector?: boolean;
  descriptionComponent?: React.ReactNode;
}

const LogoWithText: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { title, subTitle, description, withLanguageSelector, descriptionComponent } = props;

  return (
    <>
      <div className="flex flex-column justify-center logo-with-text">
        <div className="logo">
          <Logo />
        </div>
        <div className={classNames(['pb-20'], {
          'pt-20': withLanguageSelector,
          'pt-40': !withLanguageSelector,
        })}>
          {title ? <h1 className="c_black pb-20 h3">{title}</h1> : null}
          {subTitle ? <h1 className="h5 pb-10 subtitle">{subTitle}</h1> : null}
          {title ? <hr className="m-0 form-divider" /> : null}
          {description ?
            <p className="h4 m-0 c_grey pt-20 description">{description}</p> : null}
          {descriptionComponent ?
            descriptionComponent : null}
        </div>
      </div>
    </>
  );
};

export default LogoWithText;
