import { api } from './api'
import { BankIdAttemptDto, BankIdStatusDto, QrCodeDto, Referrer } from '../sdk'

export const AuthRepository = {
  async startBankId(appointmentId?: string): Promise<BankIdAttemptDto> {
    return api.auth.startBankIdAuth({ referrer: Referrer.PATIENT_PORTAL, appointmentId })
  },

  async getBankIdStatus(orderRef: string): Promise<BankIdStatusDto> {
    return api.auth.bankIdStatus(orderRef)
  },

  async getQrCodeData(orderRef: string): Promise<QrCodeDto> {
    return api.auth.getBankIdQrCodeData(orderRef)
  },

  async checkToken(): Promise<boolean> {
    return api.auth.checkAccessStrategy()
  },
}
