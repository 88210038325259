import React from 'react'
import { headerLinks } from '../../../constant/headerLinks'
import NavItemWithSubMenu from '../../NavItemWithSubmenu/NavItemWithSubMenu'
import SimpleNavItem from '../../SimpleNavItem/SimpleNavItem'


const DesktopNavbar = () => {
  return (
    <nav className="flex align-center justify-between ph-40 pv-10 navbar">
      <ul className="p-0 flex align-center">
        {
          headerLinks.map((item, index: number) => {
            if (Array.isArray(item.subLinks) && item.subLinks.length) {
              return <NavItemWithSubMenu
                key={index}
                wrapperClass="ph-20 h5 t_bold"
                submenuClass="p-absolute"
                menuItem={item} />
            }
            return <SimpleNavItem
              key={index}
              classNames="ph-20 h5 t_bold"
              menuItem={item} />
          })
        }
      </ul>
    </nav>
  )
}

export default DesktopNavbar
