import { useMutation, useQuery } from '@tanstack/react-query';
import { PaymentRepository } from '../repository/payment';

export const useGetPaymentIntent = () => {
  return useQuery({
    queryKey: ['getPaymentIntent'],
    queryFn: PaymentRepository.createPaymentIntent,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0
  })
}


export const useProcessFreeCard = () => {
  return useMutation({
    mutationFn: (appointmentId: string) => PaymentRepository.processFreeCard(appointmentId),
  })
}