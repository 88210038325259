import { useQuery } from '@tanstack/react-query'
import { SettingsRepository } from '../repository/settings'

export const useGetAppVersions = () => {
  return useQuery({
    queryKey: ['getAppVersions'],
    queryFn: () => SettingsRepository.getAppVersions(),
    retry: false
  })
}
