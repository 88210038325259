import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum ModalTypes {
  Spinner = 'Spinner',
  FreeCard = 'FreeCard'
}

type DefaultModalProps = {
  dismissible: boolean;
  onSuccess?: () => void;
}

export type ModalProps = DefaultModalProps

interface ModalState {
  opened: boolean;
  type: ModalTypes | null;
  modalProps: ModalProps;
  showModal: (type: ModalTypes, modalProps?: ModalProps) => void;
  closeModal: () => void;
}

export const useModalState = create<ModalState>()(
  devtools(
    (set) => ({
      opened: false,
      type: null,
      modalProps: { dismissible: true },
      showModal: (type, props = { dismissible: true }) =>
        set({ opened: true, type: type, modalProps: props }),
      closeModal: () => set({ opened: false, type: null, modalProps: { dismissible: true } }),
    }),
    {
      name: 'modal-state',
    },
  ),
);

export const showModal = (type: ModalTypes, props?: ModalProps) => {
  useModalState.getState().showModal(type, props);
};

export const closeModal = () => {
  useModalState.getState().closeModal();
};
