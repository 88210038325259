import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../asset/img/icon/arrow-down.svg'
import SimpleNavItem from '../SimpleNavItem/SimpleNavItem'
import { HeaderLink } from '../../constant/headerLinks'
import { useTranslation } from 'react-i18next'

type IProps = {
  menuItem: HeaderLink;
  wrapperClass?: string;
  submenuClass?: string;
}

const NavItemWithSubMenu: React.FC<IProps> = (props: IProps) => {
  const { menuItem, wrapperClass = '', submenuClass = '' } = props
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const node: any = useRef<HTMLDivElement>(null)
  const handleClickOutside = useCallback((e: Event): void => {
    if (!node.current.contains(e.target)) {
      setIsOpen(false)
      return
    }
  }, [])

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return (): void => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleItemClick = () => {
    setIsOpen(!isOpen)
  }

  const arrowClassName = isOpen ? 'rotate-180' : ''

  return (
    <li
      onClick={handleItemClick}
      className={`p-relative cursor-pointer nav-item ${wrapperClass}`}
      ref={node}>
      <span className="pr-5">{t(menuItem.title)}</span>
      <ArrowDownIcon className={arrowClassName} />
      {
        isOpen &&
          <ul className={`submenu-holder ${submenuClass}`}>
            {
              menuItem.subLinks.map((item, index: number) => {
                return <SimpleNavItem
                  key={index}
                  classNames="ph-20 pv-10 ml-20"
                  menuItem={item} />
              })
            }
          </ul>
      }
    </li>
  )
}

export default NavItemWithSubMenu
