import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';

import { useLoginWithCode } from '../../query/use-auth';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import ComplianceLinks from '../../component/ComplianceLinks/ComplianceLinks';
import { AppointmentDto } from '../../sdk';

import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
} from '../../common/local-storage';
import { useCancelAppointment } from '../../query/use-appointment';
import { APP_ROUTES } from "../../route";
import { useAppState } from '../../store/app-state'

function LoginCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appointment = getLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment);
  const [code, setCode] = useState('');

  const { mutate: cancelAppointment } = useCancelAppointment();
  const { mutate: loginWithCode } = useLoginWithCode();
  const setUser = useAppState((state) => state.setUser)

  const handleSubmit = () => {
    if (code) {
      loginWithCode(code, {
        onSuccess: (response) => {
          setUser(response.user)
          navigate(APP_ROUTES.DASHBOARD, { replace: true })
        },
      });
    }
  }

  const handleGoBack = () => {
    if (appointment) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment);
      cancelAppointment(appointment.id as string, {
        onSuccess: () => navigate(-1),
        onError: () => navigate(-1),
      });
      return;
    }
    navigate(-1);
  };


  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText
          title={t('loginCode.messages.defaultAuth')}
          description={t('loginCode.messages.defaultSubAuth')}
        />

        <input type="text" onChange={e => setCode(e.target.value)} placeholder="XXXXX"/>

        <div className="pt-20">
          <button className="g_btn g_btn-full-width g_btn--primary" onClick={handleSubmit}>
            {t('loginForm.button.login')}
          </button>
        </div>

        <div className="pt-20">
          <ComplianceLinks/>
        </div>
        <div className="pt-40 t_center">
          <button className="button link-button" onClick={handleGoBack}>
            {t('loginForm.button.back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default LoginCode;
