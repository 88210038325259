import React from 'react'
import { useTranslation } from 'react-i18next'
import LogoWithText from '../../component/LogoWithText/LogoWithText'
import { Elements } from '@stripe/react-stripe-js'
import { useAppState } from '../../store/app-state'
import { useSearchParams } from 'react-router-dom'
import { ROUTE_PARAMS } from '../../common/route-params'
import { RetrievePayment } from './RetrievePayment';

function PaymentResult() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const stripeClientSecret = searchParams.get(ROUTE_PARAMS.PaymentIntentClientSecret);

  const stripePromise = useAppState((state) => state.stripePromise)

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText
          title={`${t('stripeForm.heading')}`}
          subTitle={t('stripeForm.sub-heading')}
        />
        {stripeClientSecret ? (
          <Elements stripe={stripePromise}>
            <RetrievePayment />
          </Elements>
        ) : null}
        {/*{*/}
        {/*  error ? <h1 className='h3 t_error'>{error.name}</h1> : null*/}
        {/*}*/}
      </div>
    </main>
  )
}

export default PaymentResult
