import React from 'react'
import BurgerMenuIcon from '../../asset/img/icon/burger-menu.svg'
import { useAppState } from '../../store/app-state'

const BurgerMenu: React.FC = () => {
  const toggleMobileMenu = useAppState((state) => state.toggleMobileMenu)
  const isMenuOpen = useAppState((state) => state.isMobileMenuOpen)

  const className = `burger-menu ${isMenuOpen ? 'active' : ''}`

  return (
    <div className={className}
         onClick={toggleMobileMenu}>
      <div className="burger-menu-icon">
        <img src={BurgerMenuIcon} alt="" />
      </div>
    </div>
  )
}

export default BurgerMenu
