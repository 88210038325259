import { useMutation, useQuery } from '@tanstack/react-query'
import { AuthRepository } from '../repository/auth'
import { BankIdAttemptStatus } from '../sdk'
import { closeModal, ModalTypes, showModal } from '../store/modal-state'
import { api, updateToken } from '../repository/api'

export const useStartBankId = (appointmentId?: string) => {

  return useMutation({
    mutationFn: () => AuthRepository.startBankId(appointmentId),
  })
}

export const useGetBankIdStatus = (orderRef: string) => {
  return useQuery({
    queryKey: ['bankIdStatus'],
    queryFn: () => AuthRepository.getBankIdStatus(orderRef),
    enabled: Boolean(orderRef),
    refetchInterval: (query) => {
      const { data, error } = query.state
      if (error || (data && data.bankIdAttempt.status !== BankIdAttemptStatus.PENDING)) {
        closeModal()
        return false
      }
      if (!error && (data && data.bankIdAttempt.status === BankIdAttemptStatus.PENDING)) {
        showModal(ModalTypes.Spinner, { dismissible: false })
      }
      return 1000
    },
    retry: false, // Disable retries
    staleTime: 0, // Data is always fresh,
  })
}

export const useGetQrCodeData = (orderRef: string) => {
  return useQuery({
    queryKey: ['qrCode'],
    queryFn: () => AuthRepository.getQrCodeData(orderRef),
    enabled: Boolean(orderRef),
    refetchInterval: (query) => {
      const { data, error } = query.state
      if (error || (data && data.bankIdAttempt.status !== BankIdAttemptStatus.PENDING)) {
        return false
      }
      return 1000
    },
    retry: false,
    staleTime: 0,
  })
}

export const useCheckToken = () => {
  return useMutation({
    mutationFn: () => AuthRepository.checkToken(),
    retry: false
  })
}

export const useLoginWithCode = () => {
  return useMutation({
    mutationFn: (code: string) => api.auth.loginWithCode({ code }),
    onSuccess: (data) => {
      updateToken(data.accessToken)
    },
  })
}

export const useVerifyMobileNumber = () => {
  return useMutation({
    mutationFn: (phoneNumber: string) => api.user.verifyMobileNumber({ phoneNumber }),
  })
}

export const useMobileNumberVerified = () => {
  return useMutation({
    mutationFn: () => api.user.mobileNumberVerified(),
  })
}
