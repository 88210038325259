import { GerasApi } from '../sdk'
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../common/local-storage'

export let api = new GerasApi({
  BASE: process.env.REACT_APP_API_URL,
  TOKEN: window.localStorage.getItem(LOCAL_STORAGE_KEYS.JWT) || undefined,
})

export function updateToken(val: string | null) {
  setLocalStorageItem(LOCAL_STORAGE_KEYS.JWT, val)
  if (!val) removeLocalStorageItem(LOCAL_STORAGE_KEYS.JWT)
  reInitializeApiClient() // Reinitialize the API client with the new token
}

function reInitializeApiClient() {
  api = new GerasApi({
    BASE: process.env.REACT_APP_API_URL,
    TOKEN: window.localStorage.getItem(LOCAL_STORAGE_KEYS.JWT) || undefined
  })
}
