import React from 'react'
import FactoryLogo from '../../asset/img/branding/Factory.png'
import CElogo from '../../asset/img/branding/CE.png'
import MDlogo from '../../asset/img/branding/MD.png'
import UDIlogo from '../../asset/img/branding/UDI.png'
import REFlogo from '../../asset/img/branding/REF.png'

import { useTranslation } from 'react-i18next'
import Layout from '../../component/Layout/Layout'
import './AboutApp.scss'
import { useGetAppVersions } from '../../query/use-settings'

const AboutApp = () => {
  const { t } = useTranslation()
  const { data } = useGetAppVersions()

  return (
    <Layout>
      <div className="flex w-100 justify-between flex--wrap flex-column align-center about-app-labels">
        <div className="about-item t_left-sm pt-20-sm flex flex-column align-center">
          <div className="about-legal-mark">
            {t('intended-use-1')}

            <br />
            <br />
            {t('intended-use-2')}

          </div>
        </div>
        <div className="about-item t_left-sm pt-20-sm flex flex-column align-center">
          <div className="about-legal-mark flex align-top"><img src={CElogo} height="30" />
          </div>
          <div className="about-legal-mark flex align-top">
            <img src={MDlogo} height="30" />
            <span className="pl-10">Geras Clinic / Minnesmottagningen</span>
          </div>
          <div className="about-legal-mark flex align-top">
            <img src={UDIlogo} height="30" />
            <span className="pl-10">07350166010010</span></div>
        </div>
        <div className="about-item t_left-sm pt-20-sm flex justify-center">
          <div className="about-legal-mark flex align-top">
            <img src={REFlogo} height="30" />
            <div className="pl-10">
              <p>HCP Portal: v. {data?.hcpPortal}</p>
              <p>Patient Portal: v. {data?.patientPortal}</p>
              <p>iOS app: v. {data?.ios}</p>
              <p>Android app: v. {data?.android}</p>
              <p>{t('aboutApp.ss')}: v. {data?.symptomSurvey}</p>
            </div>
          </div>
        </div>
        <div className="about-item t_left-sm pt-20-sm flex justify-center">
          <div className="about-legal-mark flex align-top">
            <img src={FactoryLogo} height="40" />
            <span className="pl-10">Geras Solutions AB, Norrtullsgatan 6,<br />Stockholm,
                        Sweden.<br />info@gerassolutions.com
                    </span>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutApp
