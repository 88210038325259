import React from "react";
import './Spinner.scss';

const Spinner = (): JSX.Element => {
  return (
    <div className="spinner__wrapper">
      <div className='spinner__item'></div>
    </div>
  );
};

export default Spinner;
