export interface NavigationLink {
  title: string;
  internalLink: string;
  externalLinks: {
    [key: string]: string
  } | null;
  isExternal: boolean;
}

export const complianceLinks: { [key: string]: NavigationLink } = {
  privacyPolicy: {
    title: 'userPolicy.privacyPolicy',
    internalLink: '',
    externalLinks: {
      en: 'https://en.minnesmottagningen.se/integritetspolicy',
      sv: 'https://www.minnesmottagningen.se/integritetspolicy'
    },
    isExternal: true
  },
  userTerms: {
    title: 'userPolicy.userTerms',
    internalLink: '',
    externalLinks: {
      en: 'https://en.minnesmottagningen.se/anvandarvillkor',
      sv: 'https://www.minnesmottagningen.se/anvandarvillkor'
    },
    isExternal: true
  }
}