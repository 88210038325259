import React from 'react'
import SimpleNavItem from '../SimpleNavItem/SimpleNavItem'
import { footerContacts, footerLinks } from '../../constant/footerLinks'
import './Footer.scss'

const Footer: React.FC = () => {
  return (
    <footer className="footer pv-20">
      <div className="container flex justify-between align-center ph-20 footer-holder">
        <div className="footer-item t_center t_left-md pt-20 pt-0-sm">
          {
            Object.values(footerContacts).map((item, index) => {
              return <p
                key={index}
                className="m-0 pv-5 h6">{item}</p>
            })
          }
        </div>
        <div className="footer-item t_center t_right-md pt-20 pt-0-md">
          <ul className="flex flex-column-md justify-around">
            {
              footerLinks.map((item, index) => {
                return <SimpleNavItem
                  key={index}
                  classNames="pv-5 h6"
                  menuItem={item} />
              })
            }
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
