import React, { Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { AvailableTimesDto } from '../../sdk';

interface TimeSlotProps {
  bookingTimes?: AvailableTimesDto[];
  bookingTimesStatus: string;
  currentSlot: string | null;
  setCurrentSlot: Dispatch<SetStateAction<string | null>>;
  t: (key: string) => string;
}

const TimeSlots = ({ bookingTimes, bookingTimesStatus, t, currentSlot, setCurrentSlot }: TimeSlotProps) => {
  if (bookingTimes?.length === 0 && bookingTimesStatus === 'success') {
    return <p>{t('noTimeSlotsForThisDate')}</p>;
  }

  return (
    <div className="flex-container pt-10">
      {bookingTimes?.map((item: AvailableTimesDto) => (
        <div className="item--4 p-10" key={item.time}>
          <div
            className={classNames('booking__time-slot', {
              'booking__time-slot--selected': currentSlot === item.time,  // Implement selection logic if needed
            })}
            onClick={() => {
              setCurrentSlot(item.time)
              console.log(`Time slot selected: ${item.time}`);
            }}
          >
            {dayjs(item.time).format('HH:mm')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeSlots;