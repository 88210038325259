import React from 'react'
import { headerLinks } from '../../../constant/headerLinks'
import NavItemWithSubMenu from '../../NavItemWithSubmenu/NavItemWithSubMenu'
import SimpleNavItem from '../../SimpleNavItem/SimpleNavItem'
import { useAppState } from '../../../store/app-state'
import Logout from '../../Logout/Logout'

const MobileNavbar = () => {
  const isMenuOpen = useAppState((state) => state.isMobileMenuOpen)

  const className = `pv-10 p-absolute w-100 navbar navbar-mobile ${isMenuOpen ? 'active' : ''}`
  return (
    <nav className={className}>
      <div className="ph-20 pt-10">
        <Logout />
      </div>
      <div className="pt-10 pr-20 ml-auto language-wrapper">
      </div>
      <ul className="pl-0">
        {
          headerLinks.map((item, index: number) => {
            if (Array.isArray(item.subLinks) && item.subLinks.length) {
              return <NavItemWithSubMenu
                key={index}
                wrapperClass="pv-10 ph-20 h6 t_bold"
                menuItem={item} />
            }
            return <SimpleNavItem
              key={index}
              classNames="pv-10 ph-20 h6 t_bold"
              menuItem={item} />
          })
        }
      </ul>

    </nav>
  )
}

export default MobileNavbar
