import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { useGetQrCodeData, useStartBankId } from '../../query/use-auth';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import BankIdButton from '../../component/BankIdButton/BankIdButton';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS, removeLocalStorageItem,
  setLocalStorageItem,
} from '../../common/local-storage';
import { AppointmentDto } from '../../sdk';
import { APP_ROUTES } from '../../route';
import { updateToken } from '../../repository/api';

function QrCode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appointment = getLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment);

  const {
    mutate: startBankId,
    data: startBankIdData,
    status: startBankIdStatus,
  } = useStartBankId(appointment?.id ?? '');
  const { status: qrCodeStatus, data: qrCodeData } = useGetQrCodeData(
    startBankIdData?.orderRef as string,
  );

  useEffect(() => {
    if (qrCodeData?.accessToken) {
      updateToken(qrCodeData.accessToken);
      // User wants regular sign in
      if (!qrCodeData?.appointment) {
        navigate(APP_ROUTES.DASHBOARD, { replace: true });
      }
      // User has a reserved appointment which he needs to pay
      if (qrCodeData?.appointment?.status === AppointmentDto.status.RESERVED) {
        setLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment, qrCodeData.appointment);
        navigate(APP_ROUTES.PAYMENT, { replace: true });
      }
      // User already has ongoing appointment
      if (qrCodeData?.appointment?.status === AppointmentDto.status.PAID) {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment);
        navigate(APP_ROUTES.DASHBOARD, { replace: true });
      }
    }
  }, [qrCodeData]);

  const tryQrCodeAgain = () => {
    startBankId();
  };

  useEffect(() => {
    startBankId();
  }, [startBankId]);

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText
          title={t('bankId.messages.mobileBankId')}
          description={t('bankId.qrCode.tip')}
        />

        {qrCodeData?.qrCodeString && qrCodeStatus === 'success' ? (
          <>
            <div style={{ background: 'white', padding: '16px' }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={qrCodeData.qrCodeString}
                viewBox={`0 0 256 256`}
              />
            </div>
          </>
        ) : null}
        {qrCodeStatus === 'error' || startBankIdStatus === 'error' ? (
          <div className="pb-20 choose-auth-btn">
            <BankIdButton
              onClick={tryQrCodeAgain}
              classes={'g_btn-full-width'}
              label={t('bankId.qrCode.tryAgain')}
            />
          </div>
        ) : null}
        <div className="pt-40 t_center">
          <button className="button link-button" onClick={() => navigate(-1)}>
            {t('loginForm.button.back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default QrCode;
