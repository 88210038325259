import React from 'react'

interface ButtonBoxProps {
  link: string;
  buttonText: string;
  picture: string;
  additionalText: string;
}

const ButtonBox = ({ link, buttonText, picture, additionalText }: ButtonBoxProps) => {

  return (
    <div className={`component-container expanded`}>
      <div className="row-container p-10">
        <a href={link}>
          <button className="g_btn g_btn--primary g_btn-full-width pl-20 pr-20 ">{buttonText}</button>
        </a>
        <img src={picture} alt="Picture" className="picture" />
      </div>
      {additionalText && <p>{additionalText}</p>}
    </div>
  )
}

export default ButtonBox
