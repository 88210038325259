import React, { useCallback } from 'react';
import { ReactComponent as BankIdIcon } from '../../asset/img/logo/bankId-logo.svg';
import './BankIdButton.scss'
interface IProps {
  classes?: string;
  disabled?: boolean;
  label: string;
  onClick: (event?: never) => void;
}

const BankIdButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes = '', disabled = false, label, onClick } = props;

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <>
      {!disabled && (
        <button className={`login-btn login-btn__bank-id ${classes}`} onClick={handleClick}>
          <span className="pr-5">{label}</span>
          <BankIdIcon />
        </button>
      )}
      {disabled && (
        <button className={`login-btn login-btn__bank-id login-btn__bank-id--disabled ${classes}`}>
          <span className="pr-5">{label}</span>
          <BankIdIcon />
        </button>
      )}
    </>
  );
};

export default BankIdButton;
