import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import { APP_ROUTES } from '../../route'
import { useTranslation } from 'react-i18next'

interface IProps {
  amountText: string;
}
export const CheckoutForm: React.FC<IProps> = (props: IProps) => {
  const { i18n } = useTranslation()
  const { amountText } = props

  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (e: any) => {
    // TODO check appointment is still valid
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + `${APP_ROUTES.PAYMENT_RESULT}?lang=${i18n.language}`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      toast.error(error.message)
    } else {
      toast.error('An unexpected error occurred.')
    }

    setLoading(false)

  }

  return (
    <>
        <>
          <PaymentElement />
          <div className="pt-20 t_center">
            <button
              type="button"
              className="login-btn g_btn g_btn--app-cta-primary w-100"
              disabled={!stripe || !elements || loading}
              onClick={handleSubmit}
            >{`${t('stripeForm.ctaButton')} ${amountText}`}</button>
          </div>
        </>
    </>
  );
};
