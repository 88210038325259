import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { api } from './repository/api';
import { TranslationApp } from './sdk';

const translationsCache = {}
let translationDownloadPromise
const pullTranslations = async (languageParam) => {
    const language = languageParam.split('-')[0]
    if (translationDownloadPromise) {
        await translationDownloadPromise
    }

    if (Object.keys(translationsCache).length === 0) {
        translationDownloadPromise = api.translations.getBatch([TranslationApp.GERAS_COMMON, TranslationApp.GERAS_PATIENT_PORTAL])
        const translations = await translationDownloadPromise
        for (const appTranslations of Object.values(translations)) {
            for (const lang in appTranslations) {
                translationsCache[lang] = Object.assign({}, translationsCache[lang], appTranslations[lang])
            }
        }
    }

    return {
        status: 200,
        data: JSON.stringify(translationsCache[language])
    }
}

i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: false,
        backend: {
            request: (options, url, payload, callback) =>
                pullTranslations(url)
                    .then(r => callback(null, r))
                    .catch(e => callback(e)),
            // To path it to the custom request function
            loadPath: '{{lng}}',
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
