import { toast } from 'react-toastify'
import { updateToken } from '../repository/api'
import { APP_ROUTES } from '../route'

export type ApiError = {
  statusText?: string
  body?: {
    error: string
    message: Array<string>
    statusCode: number;
  };
  message: string;
};

export const handleGlobalError = (error: ApiError) => {
  try {
    if (error?.body?.statusCode === 401) {
      updateToken(null)

      if (![APP_ROUTES.LOGIN, APP_ROUTES.BANK_ID, APP_ROUTES.LOGIN_CODE].includes(window.location.pathname)) {
        window.location.replace(APP_ROUTES.LOGIN)
      }
    }
    const errMsg = error?.body?.message || ['Something went wrong']
    toast.error(errMsg.join(' '), { autoClose: 3000, pauseOnHover: false, pauseOnFocusLoss: true })
  } catch (e) {
    toast.error('Internal server error', { autoClose: 3000, pauseOnHover: false, pauseOnFocusLoss: true })
  }
}
