import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';

import LogoWithText from '../../component/LogoWithText/LogoWithText';

function HelpRelative() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <main className="wrapper flex flex-column align-center justify-center">

      <div className="start-page-wrapper">
        <LogoWithText
          title={t('help-relative.title')}
          description={`${t('help-relative.description')}`}
        />
        <div className="pt-20 t_center">
          <button className="button link-button" onClick={() => navigate(-1)}>
            {t('loginForm.button.back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default HelpRelative;
