import React from 'react';
import { useTranslation } from 'react-i18next';
import { complianceLinks } from '../../constant/compliance-links';

const ComplianceLinks = () => {
  const { privacyPolicy, userTerms } = complianceLinks;
  const { t } = useTranslation();

  const privacyPolicyLink = privacyPolicy.externalLinks
    ? privacyPolicy.externalLinks["sv"] || privacyPolicy.externalLinks["en"]
    : '';
  const userTermsLink = userTerms.externalLinks
    ? userTerms.externalLinks["sv"] || userTerms.externalLinks["en"]
    : '';

  return (
    <p className="t_center h6 m-0 t_lightText">
      <span className="mr-5 d-inline-block">{t('userPolicy.ComfirmationText')}</span>
      <a className="mr-5 d-inline-block underlined" target="_blank" href={privacyPolicyLink} rel="noreferrer">
        {t(privacyPolicy.title)}
      </a>
      <span className="mr-5 d-inline-block">&</span>
      <a className="d-inline-block underlined" target="_blank" href={userTermsLink} rel="noreferrer">
        {t(userTerms.title)}
      </a>
    </p>
  );
};

export default ComplianceLinks;
