import { useQuery, useMutation } from '@tanstack/react-query'
import { UserRepository } from '../repository/user'
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from '../common/local-storage'
import { api } from '../repository/api'

export const useGetFiles = () => {
  return useQuery({
    queryKey: ['getFiles'],
    queryFn: () => UserRepository.getFiles(),
    retry: false
  })
}

export const useGetProfile = () => {
  return useMutation({
    mutationFn: UserRepository.getProfile,
    retry: false
  })
}

export const useDownloadFile = () => {
  return useMutation({
    mutationFn: async (id: string): Promise<Blob> => {
      const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.JWT)

      // Using fetch cause sdk can't return blob
      const data = await fetch(api.request.config.BASE + '/user/download-patient-file/' + id, {
        method: 'GET',
        headers: {
          'Response-Type': 'blob',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
      })

      return data.blob()
    },
  })
}
