import React from 'react'
import { useWindowDimensions } from '../../hook/windowDimensionHandler'
import DesktopNavbar from './DesktopNavbar/DesktopNavbar'
import MobileNavbar from './MobileNavbar/MobileNavbar'
import './Navbar.scss'

const Navbar: React.FC = () => {
  const { width } = useWindowDimensions()
  const isDesktop = width > 768
  return (
    <div className="navbar-wrapper">
      {isDesktop && <DesktopNavbar />}
      {!isDesktop && <MobileNavbar />}
    </div>
  )
}

export default Navbar
