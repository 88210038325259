import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { useMobileNumberVerified, useVerifyMobileNumber } from '../../query/use-auth';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import { APP_ROUTES } from "../../route";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import classNames from "classnames";

function MobileVerification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [smsWasSent, setSmsWasSent] = useState(false);
  const submitIsDisabled = phone.length < 10;

  const { mutate: verifyMobileNumber } = useVerifyMobileNumber();
  const { mutate: mobileNumberVerified } = useMobileNumberVerified();

  useEffect(() => {
    if (phone && phone.startsWith("+460")) {
      const newPhoneNumber = phone.replace("+460", "+46");
      setPhone(newPhoneNumber);
    }
  }, [phone]);

  // TODO check if phone already verified => redirect strict to dashboard
  const handleMobileNumberSubmit = () => {
    verifyMobileNumber(phone);
    setSmsWasSent(true);
  };

  const handleSmsWasSent = () => {
    mobileNumberVerified();
    navigate(APP_ROUTES.BOOKING_CONFIRMATION);
  };
  const handleSmsWasNotSent = () => {
    setSmsWasSent(false);
  };

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        {!smsWasSent && (
          <>
            <LogoWithText
              title={t('phoneConfirmationPage.step1.title')}
              description={t('phoneConfirmationPage.step1.description')}
            />
            <PhoneInput
              defaultCountry="se"
              value={phone}
              forceDialCode={true}
              autoFocus={true}
              inputStyle={{ fontSize: 18 }}
              onChange={(phone) => setPhone(phone)}
            />
            <div className="pt-20">
              <button
                className={classNames('g_btn g_btn--primary g_btn-full-width ', {
                  'g_btn--disabled': submitIsDisabled,
                })}
                onClick={handleMobileNumberSubmit}
                disabled={submitIsDisabled}
              >
                {t('phoneConfirmationPage.step1.button.cta')}
              </button>
            </div>
          </>
        )}

        {smsWasSent && (
          <>
            <LogoWithText
              title={t('phoneConfirmationPage.step2.title')}
              description={t('phoneConfirmationPage.step2.description')}
            />
            <div className="pt-20">
              <button className="g_btn g_btn--primary g_btn-full-width" onClick={handleSmsWasSent}>
                {t('phoneConfirmationPage.step2.button.cta1')}
              </button>

              <button
                className="g_btn g_btn--primary g_btn-full-width mt-20"
                onClick={handleSmsWasNotSent}
              >
                {t('phoneConfirmationPage.step2.button.cta2')}
              </button>
            </div>
          </>
        )}
        <div className="w-100 t_center mt-10 mb-10">
          <span>
            {t('support-center')}{' '}
            <a className="t_italic link-button c-pointer" href={`tel:${t('support-number')}`}>
              {t('support-number')}
            </a>
          </span>
        </div>

      </div>
    </main>
  );
}

export default MobileVerification;
