import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import SpecialistImage from '../../asset/img/geras_specialists.png';
import { ReactComponent as LeftArrowIcon } from '../../asset/img/icon/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../asset/img/icon/right-arrow.svg';
import './GetStarted.scss';
import {
  useCancelAppointment,
  useCreateAppointment,
  useGetBookingDates,
  useGetBookingTimes,
} from '../../query/use-appointment';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import TimeSlots from './TimeSlots';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../route';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../common/local-storage';
import { AppointmentDto } from '../../sdk';

import('dayjs/locale/en')
import('dayjs/locale/sv')

dayjs.extend(timezone);

enum OPERATION {
  Increment,
  Decrement,
}

function GetStarted() {
  const { t } = useTranslation();
  const month = dayjs().format('YYYY-MM');
  const userTz = dayjs.tz.guess();
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(month);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [currentTimeSlot, setCurrentTimeSlot] = useState<string | null>(null);
  const { mutate: createAppointment } = useCreateAppointment(currentTimeSlot ?? '');

  const { data: bookingDates, status: bookingDatesStatus } = useGetBookingDates(currentMonth, userTz);
  const { data: bookingTimes, status: bookingTimesStatus, refetch: refetchBookingTimes } = useGetBookingTimes(
    bookingDates?.[currentDayIndex]?.date ?? '',
    userTz,
  );
  const { mutate: cancelAppointment } = useCancelAppointment();

  // No meeting days this month => go next month
  useEffect(() => {
    if (bookingDatesStatus === "success" && bookingDates?.length === 0) {
      const currentDate = dayjs();
      const nextMonth = currentDate.add(1, 'month');
      const formattedNextMonth = nextMonth.format('YYYY-MM');
      setCurrentMonth(formattedNextMonth)
    }

  }, [bookingDates]);

  // user arrived to this page to book a meeting, if he had previously selected appointment => cancel it
  useEffect(() => {
    const appointmentId = getLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment)?.id
    if (appointmentId) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment);
      cancelAppointment(appointmentId)
    }

  }, []);

  const handleNext = () => {
    createAppointment(undefined, {
      onSuccess: (data) => {
        setLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment, data);
        const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.JWT);
        if (token) {
          navigate(APP_ROUTES.PAYMENT, { replace: true });
        } else {
          navigate(APP_ROUTES.BANK_ID);
        }
      },
      onError: () => {
        refetchBookingTimes().then()
      }
    })
  }

  const changeDay = useCallback(
    (operation: OPERATION) => {
      setCurrentTimeSlot(null);
      const totalDatesIndex = Math.max(0, (bookingDates?.length ?? 0) - 1);

      setCurrentDayIndex((prevState) => {
        switch (operation) {
          case OPERATION.Decrement:
            // Go back to the end of month
            if (prevState === 0) {
              return Math.max(0, totalDatesIndex);
            }
            return prevState - 1;
          case OPERATION.Increment:
            // Go to the beginning of month
            if (prevState === totalDatesIndex) {
              return 0;
            }
            return prevState + 1;
          default:
            // Default case returns the current state if no operation matches
            return prevState;
        }
      });
    },
    [bookingDates],
  );

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper pt-10 pb-10">
        <LogoWithText
          title={t('bookingPage.customStep.title')}
          description={t('bookingPage.customStep.description')}
        />
        <img src={SpecialistImage} alt="geras_specialists" style={{ width: '100%' }} />
        <div className="m-20 flex justify-between align-center">
          <button
            className="booking__month-btn booking__month-btn--btn-prev"
            onClick={() => changeDay(OPERATION.Decrement)}
          >
            <LeftArrowIcon />
          </button>
          {bookingDates?.[currentDayIndex]?.date ? (
            <>
              <div className="h5 c_black">
                <p className="m-0 pb-5 t_center bold h4">
                  {dayjs(bookingDates[currentDayIndex].date).format('DD MMMM')}
                </p>
                <p className="m-0 t_center">
                  {dayjs(bookingDates[currentDayIndex].date).format('dddd')}
                </p>
              </div>
            </>
          ) : null}
          <button
            className="booking__month-btn booking__month-btn--btn-next"
            onClick={() => changeDay(OPERATION.Increment)}
          >
            <RightArrowIcon />
          </button>
        </div>
        <TimeSlots
          bookingTimes={bookingTimes}
          bookingTimesStatus={bookingTimesStatus}
          t={t}
          currentSlot={currentTimeSlot}
          setCurrentSlot={setCurrentTimeSlot}
        />
        <button
          onClick={handleNext}
          className={classNames('g_btn g_btn--primary g_btn-full-width mt-10 mb-10', {
            'g_btn--disabled': !currentTimeSlot,
          })}
        >
          {t('bookingPage.customStep.button.cta1')}
        </button>
        <span className="mt-10 mb-5 d-inline-block t_center">
          {t('get-started.help-description')}
        </span>

        <div className="w-100 t_center mt-10">
          <span>
            {t('get-started.help-relative')} -{' '}
            <a
              className="t_italic link-button c-pointer"
              onClick={() => navigate(APP_ROUTES.HELP_RELATIVE)}
            >
              {t('button.click-here')}
            </a>
          </span>
        </div>

        <div className="w-100 t_center mt-10 mb-10">
          <span>
            {t('support-center')} <a className="t_italic link-button c-pointer" href={`tel:${t('support-number')}`}>{t('support-number')}</a>
          </span>
        </div>

        <div className="t_center">
          <button
            className="button link-button"
            onClick={() => window.location.replace('https://minnesmottagningen.se/')}
          >
            {t('loginForm.button.back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default GetStarted;
