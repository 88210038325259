import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import './Payment.scss';
import { useGetPaymentIntent, useProcessFreeCard } from '../../query/use-payment';
import dayjs from 'dayjs';
import { closeModal, ModalTypes, showModal } from '../../store/modal-state';
import { Elements } from '@stripe/react-stripe-js';
import { useAppState } from '../../store/app-state';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../route';
import { StripeElementLocale } from '@stripe/stripe-js/dist/stripe-js/elements-group';

function Payment() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { data, error, status } = useGetPaymentIntent();
  const { mutate: processFreeCard } = useProcessFreeCard();
  const stripePromise = useAppState((state) => state.stripePromise);
  const amountText = data?.amount && data?.currency ? `${(data.amount / 100).toFixed(2)}kr` : '';

  // TODO increase reserved time for 5 mins on arriving to this page => mid prior

  const options: StripeElementsOptions = {
    clientSecret: data?.client_secret,
    locale: (i18n.language ?? 'en') as StripeElementLocale,
    appearance: {
      theme: 'flat',
      variables: {
        fontSizeBase: '16px',
      },
    },
    loader: 'always',
  };

  useEffect(() => {
    // @ts-ignore
    if (error && (error.status === 401 || error.status === 404)) {
      // unauthorized or no appointment found
      navigate(APP_ROUTES.GET_STARTED, { replace: true });
    }
  }, [error]);

  useEffect(() => {
    if (status === 'pending') {
      showModal(ModalTypes.Spinner);
    } else {
      closeModal();
    }
  }, [status]);

  const handleFreeCard = () => {
    showModal(ModalTypes.FreeCard, {
      dismissible: true,
      onSuccess: () => {
        if (data?.appointment.id) {
          showModal(ModalTypes.Spinner);
          processFreeCard(data.appointment.id, {
            onSuccess: () => {
              closeModal();
              navigate(APP_ROUTES.MOBILE_VERIFICATION);
            },
            onError: () => {
              closeModal();
            },
          });
        }
      },
    });
  };

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText
          title={`${t('stripeForm.heading')} ${amountText}`}
          subTitle={t('stripeForm.sub-heading')}
        />
        <p className="h6 m-0 c_grey pt-15">
          {t('bookingPage.paymentStep.description.priceIncludes')}
        </p>
        <li className="h6 m-0 c_grey">{t('bookingPage.paymentStep.description.line1')}</li>
        {data?.appointment.date ? (
          <p className="h6 m-0 c_grey ml-20">
            <span className="t_bold">
              {dayjs(data.appointment.date).format('D MMM HH:mm')}
            </span>
          </p>
        ) : null}
        <li className="h6 m-0 c_grey">{t('bookingPage.paymentStep.description.line2')}</li>
        <li className="h6 m-0 c_grey">{t('bookingPage.paymentStep.description.line3')}</li>
        <li className="h6 m-0 c_grey">{t('bookingPage.paymentStep.description.line4')}</li>
        <span>
              {t('bookingPage.paymentStep.description.line4-1')}
        </span>
        {data?.client_secret ? (
          <button
            type="button"
            className="free-card-stripeDesign mt-20 pb-20 button link-button green"
            onClick={handleFreeCard}
          >
            {t('bookingPage.paymentStep.freeCard')}
          </button>
        ) : null}
        {/*There is useStripe in CheckoutForm which require client_secret, even though useGetPaymentIntent won't be called again
        after user returns from success payment we can get secret from query params that stripe adds, we need it
        to render CheckoutForm so the useEffect inside CheckoutForm component will be called*/}
        {data?.client_secret ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm amountText={amountText} />
          </Elements>
        ) : null}
        {/*{*/}
        {/*  error ? <h1 className='h3 t_error'>{error.name}</h1> : null*/}
        {/*}*/}

        <div className="w-100 t_center mt-10">
          <span>
            {t('support-center')} <a className="t_italic link-button c-pointer"
                                     href={`tel:${t('support-number')}`}>{t('support-number')}</a>
          </span>
        </div>

        <div className="mb-10 mt-10 t_center">
          <button className="button link-button" onClick={() => navigate(APP_ROUTES.GET_STARTED, { replace: true })}>
            {t('bookingPage.changeTimeSlot')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default Payment;
