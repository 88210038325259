import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom';
import './i18n'
import './style/app.scss'
import { router } from './route'
import { ToastContainer } from 'react-toastify'
import ModalOverlay from './component/modal/ModalOverlay/ModalOverlay'

import { ROUTE_PARAMS } from './common/route-params'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useGetProfile } from './query/use-user'
import { useAppState } from './store/app-state'
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './common/local-storage';
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const ParamsLanguageSetter = () => {
  const { i18n } = useTranslation()
  // cannot use "useSearchParams" cause outside of router
  const searchParams = new URLSearchParams(window.location.search)
  let language = searchParams.get(ROUTE_PARAMS.Lang)
  if (language) {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.Language, language)
  } else {
    language = getLocalStorageItem(LOCAL_STORAGE_KEYS.Language) || 'sv'
  }

  dayjs.locale(language)
  if (i18n.language !== language) {
    i18n.changeLanguage(language)
  }

  return <></>
}

const AppRoot = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const orderRef = searchParams.get(ROUTE_PARAMS.OrderReF)
  const { mutate: getProfile, data: userProfile } = useGetProfile()
  const setUser = useAppState(s => s.setUser)

  useEffect(() => {
    if (process.env.REACT_APP_ENV) {
      datadogRum.init({
        applicationId: 'ee979058-3381-450a-be88-afbc56c49a0e',
        clientToken: 'pub1b54ff84164e432558d17594405bdc70',
        site: 'datadoghq.eu',
        service: 'patient-portal',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      })

      datadogLogs.init({
        clientToken: 'pub1b54ff84164e432558d17594405bdc70',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        site: 'datadoghq.eu',
        env: process.env.REACT_APP_ENV,
        service: 'patient-portal',
        useSecureSessionCookie: true,
      })

    }
  }, [])
  useEffect(() => {
    if (orderRef) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.JWT)
    }
    const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.JWT)
    if (token) {
      getProfile()
    }
  }, [])

  useEffect(() => {
    if (userProfile) {
      setUser(userProfile)
    }
  }, [userProfile])

  // const { mutate: checkToken } = useCheckToken()
  // TODO onError add refresh token
  // useEffect(() => {
  //   const token = getLocalStorageItem(LOCAL_STORAGE_KEYS.JWT);
  //   if (token) {
  //     checkToken(undefined, {});
  //   }
  // }, [checkToken]);

  return (
    <>
      <RouterProvider router={router} />
      <ModalOverlay />
      <ToastContainer />
      <ParamsLanguageSetter />
    </>
  )
}

export default AppRoot
