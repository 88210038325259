import React, { useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PARAMS } from '../../common/route-params'
import { useHandlePaidAppointment } from '../../query/use-appointment'
import { APP_ROUTES } from '../../route'
import { closeModal, ModalTypes, showModal } from '../../store/modal-state';

export const RetrievePayment = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const paymentIntentClientSecret = searchParams.get(ROUTE_PARAMS.PaymentIntentClientSecret)
  const { mutate: handlePaidAppointment } = useHandlePaidAppointment()

  const stripe = useStripe()

  useEffect(() => {

    if (!stripe) {
      return
    }

    if (!paymentIntentClientSecret) {
      return
    }

    showModal(ModalTypes.Spinner);
    
    stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded': {
          handlePaidAppointment(paymentIntent.id as string)
          closeModal()
          navigate(APP_ROUTES.MOBILE_VERIFICATION)
          break
        }
        default:
          closeModal()
          navigate(APP_ROUTES.PAYMENT)
          break
      }
    })
  }, [stripe, paymentIntentClientSecret])

  return (<></>);
};
