import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../store/app-state'
import { updateToken } from '../../repository/api'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../route'

const Logout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppState((state) => state.user)

  const handleLogout = () => {
    updateToken(null)
    navigate(APP_ROUTES.LOGIN)
  }

  return (
    <>
      {user &&
          <>
              <div className="flex align-center">
                  <p className="t_size_16 cursor-pointer t_bold hover-underlined"
                     onClick={handleLogout}>{t('word.logout')}</p>
                  <span className="ph-10">|</span>
                  <p className="t_size_16 t_bold">{`${user.firstName} ${user.lastName}`}</p>
              </div>
          </>
      }
    </>
  )
}

export default Logout
