import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetBankIdStatus, useStartBankId } from '../../query/use-auth';
import LogoWithText from '../../component/LogoWithText/LogoWithText';
import BankIdButton from '../../component/BankIdButton/BankIdButton';
import ComplianceLinks from '../../component/ComplianceLinks/ComplianceLinks';
import { AppointmentDto, BankIdAttemptDto } from '../../sdk';
import { BankIdAuthQueries } from '../../type/auth';
import { APP_ROUTES } from '../../route';
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../common/local-storage';
import { ROUTE_PARAMS } from '../../common/route-params';
import { useCancelAppointment } from '../../query/use-appointment';
import { updateToken } from '../../repository/api';
import { useAppState } from '../../store/app-state'
import dayjs from 'dayjs';
import { datadogLogs } from '@datadog/browser-logs';

function BankId() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isChrome, isAndroid, isIOS, isSafari } = getSelectorsByUserAgent(
    window.navigator.userAgent,
  );
  const appointment = getLocalStorageItem<AppointmentDto>(LOCAL_STORAGE_KEYS.Appointment);
  const orderRef = searchParams.get(ROUTE_PARAMS.OrderReF);

  const { mutate: startBankId } = useStartBankId(appointment?.id ?? '');
  const { data: getBankIdStatusData } = useGetBankIdStatus(orderRef as string);
  const { mutate: cancelAppointment } = useCancelAppointment();
  const setUser = useAppState((state) => state.setUser);

  useEffect(() => {

    if (getBankIdStatusData?.user) {
      setUser(getBankIdStatusData.user);
    }
    if (getBankIdStatusData?.accessToken) {
      updateToken(getBankIdStatusData.accessToken);
      // User wants regular sign in
      if (!getBankIdStatusData?.appointment) {
        navigate(APP_ROUTES.DASHBOARD, { replace: true });
        return
      }
      datadogLogs.logger.info('appointment', getBankIdStatusData?.appointment)
      // User has a reserved appointment which he needs to pay
      if (getBankIdStatusData?.appointment?.status === AppointmentDto.status.RESERVED) {
        setLocalStorageItem<AppointmentDto>(
          LOCAL_STORAGE_KEYS.Appointment,
          getBankIdStatusData.appointment,
        );
        navigate(APP_ROUTES.PAYMENT, { replace: true });
        return
      }
      // User already has ongoing appointment
      if (getBankIdStatusData?.appointment?.status === AppointmentDto.status.PAID) {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment);
        navigate(APP_ROUTES.DASHBOARD, { replace: true });
        return
      }
    }
  }, [getBankIdStatusData]);

  const handleStartBankIdOnThisDevice = () => {
    startBankId(undefined, {
      onSuccess: (data) => {
        if (data.autoStartToken) {
          openBankIdApp(data);
        }
      },
    });
  };

  const handleGoBack = () => {
    if (appointment) {
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.Appointment);
      cancelAppointment(appointment.id as string, {
        onSuccess: () => navigate(-1),
        onError: () => navigate(-1),
      });
      return;
    }
    navigate(-1);
  };

  const openBankIdApp = (data: BankIdAttemptDto) => {
    const redirectUrl = encodeURIComponent(
      `${window.location.origin}${window.location.pathname}/?orderRef=${data.orderRef}&appointmentId=${appointment?.id}&lang=${i18n.language}`,
    );
    let bankIdLink = `bankid:///?${BankIdAuthQueries.AutoStartToken}=${data.autoStartToken}&${BankIdAuthQueries.Redirect}=${redirectUrl}`;
    // TODO refactor this if statement, looks odd
    if ((isAndroid && isChrome) || (isIOS && isSafari)) {
      bankIdLink = `https://app.bankid.com/?${BankIdAuthQueries.AutoStartToken}=${data.autoStartToken}&${BankIdAuthQueries.Redirect}=${redirectUrl}`;
    }
    window.location.replace(bankIdLink);
  };

  const handleStartBankIdAnotherDevice = () => {
    navigate(APP_ROUTES.QR_CODE);
  };

  return (
    <main className="wrapper flex flex-column align-center justify-center">
      <div className="start-page-wrapper">
        <LogoWithText
          title={appointment?.date ? dayjs(appointment.date).format('D MMM HH:mm') : t('bankId.messages.mobileBankId')}
          description={appointment?.date ? t('bankId.confirm-slot') : t('bankId.qrCode.tip')}
        />

        <div className="pb-20 choose-auth-btn">
          <BankIdButton
            onClick={handleStartBankIdOnThisDevice}
            classes={'g_btn-full-width'}
            label={t('bankId.button.startThisDevice')}
          />
        </div>
        <div className="pb-20 choose-auth-btn">
          <BankIdButton
            onClick={handleStartBankIdAnotherDevice}
            classes={'g_btn-full-width'}
            label={t('bankId.button.startAnotherDevice')}
          />
        </div>
        <div className="pt-20">
          <ComplianceLinks />
        </div>

        <div className="w-100 t_center mt-10 mb-10">
          <span>
            {t('support-center')} <a className="t_italic link-button c-pointer"
                                     href={`tel:${t('support-number')}`}>{t('support-number')}</a>
          </span>
        </div>

        <div className="pt-10 t_center">
          <button className="button link-button" onClick={handleGoBack}>
            {t('loginForm.button.back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default BankId;
