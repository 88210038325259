import { useMutation, useQuery } from '@tanstack/react-query';
import { AppointmentRepository } from '../repository/appointment';

export const useGetAppointment = (id: string) => {
  return useQuery({
    queryKey: ['getAppointmentById'],
    queryFn: () => AppointmentRepository.getAppointmentById(id),
  })
}

export const useGetBookingDates = (month: string, timezone: string) => {
  return useQuery({
    queryKey: ['getBookingDates', month],
    queryFn: () => AppointmentRepository.getAvailableDates(month, timezone),
  })
}

export const useGetBookingTimes = (date: string, timezone: string) => {
  return useQuery({
    queryKey: ['getBookingTimes', date],
    enabled: Boolean(date),
    queryFn: () => AppointmentRepository.getAvailableTimes(date, timezone),
    staleTime: 0,
  })
}

export const useCreateAppointment = (timeSlot: string) => {
  return useMutation({
    mutationFn: () => AppointmentRepository.createAppointment(timeSlot)
  })
}

export const useCancelAppointment = () => {
  return useMutation({
    mutationFn: (id: string) => AppointmentRepository.cancelAppointmentById(id)
  })
}

export const useHandlePaidAppointment = () => {
  return useMutation({
    mutationFn: (paymentIntentId: string) => AppointmentRepository.handlePaidAppointment(paymentIntentId)
  })
}