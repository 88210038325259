import { create } from 'zustand'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { UserDto } from '../sdk'

interface AppState {
  stripePromise: Promise<Stripe | null>
  isMobileMenuOpen: boolean
  toggleMobileMenu: () => void
  user: UserDto | null
  setUser: (user: UserDto) => void
}

export const useAppState = create<AppState>((set) => ({
  stripePromise: loadStripe(process.env.REACT_APP_STRIPE_KEY ?? ''),

  user: null,
  setUser: (user: UserDto) => set(() => ({ user })),
  isMobileMenuOpen: false,
  toggleMobileMenu: () => set((state) => ({ isMobileMenuOpen: !state.isMobileMenuOpen }))
}))
