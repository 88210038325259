import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import Login from '../page/login/Login';
import GetStarted from '../page/get-started/GetStarted';
import BankId from '../page/bank-id/BankId';
import QRcode from '../page/qr-code/QRcode';
import Payment from '../page/payment/Payment';
import Dashboard from '../page/dashboard/Dashboard';
import LoginCode from '../page/login-code/LoginCode';
import MobileVerification from '../page/mobile-verification/MobileVerification';
import BookingConfirmation from '../page/booking-confirmation/BookingConfirmation';
import AboutApp from '../page/about-app/AboutApp'
import PaymentResult from '../page/payment-result/PaymentResult';
import HelpRelative from '../page/help-relative/HelpRelative';

export const APP_ROUTES = {
  GET_STARTED: '/get-started',
  LOGIN: '/login',
  BANK_ID: '/bank-id',
  LOGIN_CODE: '/login-code',
  MOBILE_VERIFICATION: '/confirm-phone',
  BOOKING_CONFIRMATION: '/booking-confirmation',
  QR_CODE: '/qr-code',
  DASHBOARD: '/dashboard',
  PAYMENT: '/payment',
  PAYMENT_RESULT: '/payment-result',
  ABOUT_APP: '/about-app',
  HELP_RELATIVE: '/help-relative',
}

const AppRoutes = [
  {
    path: APP_ROUTES.GET_STARTED,
    element: <GetStarted />,
  },
  {
    path: APP_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: APP_ROUTES.BANK_ID,
    element: <BankId />,
  },
  {
    path: APP_ROUTES.LOGIN_CODE,
    element: <LoginCode />,
  },
  {
    path: APP_ROUTES.MOBILE_VERIFICATION,
    element: <MobileVerification />,
  },
  {
    path: APP_ROUTES.BOOKING_CONFIRMATION,
    element: <BookingConfirmation />,
  },
  {
    path: APP_ROUTES.QR_CODE,
    element: <QRcode />,
  },
  {
    path: APP_ROUTES.PAYMENT,
    element: <Payment />,
  },
  {
    path: APP_ROUTES.PAYMENT_RESULT,
    element: <PaymentResult />,
  },
  {
    path: APP_ROUTES.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: APP_ROUTES.ABOUT_APP,
    element: <AboutApp />,
  },
  {
    path: APP_ROUTES.HELP_RELATIVE,
    element: <HelpRelative />,
  },
  {
    path: '*',
    element: <Login />,
  },
];

export const router = createBrowserRouter(AppRoutes);
