import { api } from './api'

export const UserRepository = {
  async getFiles() {
    return api.user.getAllPatientFiles()
  },

  async getProfile() {
    return api.user.getProfile()
  }
}
