export enum LOCAL_STORAGE_KEYS {
  Language = 'Language',
  Appointment = 'Appointment',
  JWT = 'gerasToken'
}

export const getLocalStorageItem = <T>(key: LOCAL_STORAGE_KEYS): T | null => {
  const val = localStorage.getItem(key);
  if (val === null) {
    return null;
  }
  try {
    // Try to parse it as JSON
    return JSON.parse(val) as T;
  } catch {
    return val as unknown as T;
  }
};

export const setLocalStorageItem = <T>(key: LOCAL_STORAGE_KEYS, val: T) => {
  if (typeof val === 'object' && val !== null || Array.isArray(val)) {
    localStorage.setItem(key, JSON.stringify(val));
  } else {
    // Store primitives as-is
    localStorage.setItem(key, val as unknown as string);
  }
};

export const removeLocalStorageItem = (key: LOCAL_STORAGE_KEYS) => {
  return localStorage.removeItem(key)
};
